<template>
  <div>
    <vue-good-table
      id="tablePr"
      ref="tablePr"
      :columns="columns"
      :rows="rows"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        clearSelectionText: 'Seçili olanları temizle',
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      @on-selected-rows-change="selectionChanged"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <b-img
          v-if="props.column.field === 'photoUrl'"
          :src="props.row.photo.icon_url"
        />
        <div v-else-if="props.column.field === 'activeStatus'">

          <b-button
            v-if="props.row.activeStatus !== true"
            variant="flat-success"
            @click="setStatus(props.row.id, true)"
          >
            <feather-icon
              v-if="spinnerId !== props.row.id"
              icon="CheckIcon"
              class="mr-50"
            />
            <span class="align-middle">Göster</span>
            <b-spinner
              v-if="spinnerId === props.row.id"
              small
              class="ml-50"
            />
          </b-button>
          <b-button
            v-else
            variant="flat-danger"
            @click="setStatus(props.row.id, false)"
          >
            <feather-icon
              v-if="spinnerId !== props.row.id"
              icon="XIcon"
              class="mr-50"
            />
            <span class="align-middle">Gizle</span>
            <b-spinner
              v-if="spinnerId === props.row.id"
              class="ml-50"
              small
            />
          </b-button>
        </div>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-input
              v-model="pageLength"
              class="mx-1"
              type="number"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BPagination, BFormInput, BImg, BButton, BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BPagination, BFormInput, VueGoodTable, BImg, BButton, BSpinner,
  },
  data() {
    return {
      rows: [],
      featuredProducts: [],
      pageLength: 15,
      searchTerm: '',
      spinnerId: false,
      columns: [
        {
          label: 'Göster',
          field: 'activeStatus',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Ürün İsmi',
          field: 'name',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Kategori',
          field: 'category_name',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Ürün resmi',
          field: 'photoUrl',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('product', ['getProductsData']),
  },
  created() {
    this.filterProducts()
  },
  methods: {
    ...mapActions('product', ['getProducts']),
    filterProducts(flt = null) {
      this.getProducts(flt).then(() => {
        this.rows = this.getProductsData
      })
    },
    selectionChanged(e) {
      this.featuredProducts = e.selectedRows
    },
    setStatus(id, status) {
      this.spinnerId = id
      this.$http.patch('product/active', {
        id,
        status,
      }).then(res => {
        if (res) {
          const index = this.rows.findIndex(x => x.id === id)
          this.rows[index].activeStatus = status
        }
        this.spinnerId = null
      })
    },
  },

}
</script>
